import React, {useState} from "react"
import Sensor from 'react-visibility-sensor'
import { Navbar, Nav, Container, Button} from "react-bootstrap"
import { navigate, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Mac from "../Mac"
import Spacer from "../Spacer"
import Arrow from "./Arrow"

import "./style.css"
import logo_light from './logo-light.svg';
import logo_dark from './logo-dark.svg';


export default (props) => {
    const [visible, setVisible] = useState(true)
    
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "background.jpg" }) {
        childImageSharp { 
          fluid(maxWidth: 1920, quality: 90 ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

    
    if(props.hero){
        return hero(visible, setVisible, data)
    }
    else{
        return(
        <header>
            <nav className="d-flex">
                <Navbar bg="light" fixed="top">
                <Container className="flex-column flex-md-row">
                    <Navbar.Brand href="/" className="col-5 col-md-2"><img src={logo_dark} className="d-inline-block align-top img-fluid" alt="Zorrilla Media"/></Navbar.Brand>
                    <Nav className="ml-md-auto flex-row d-none d-md-flex">
                        <Button className="btn-link mx-2 text-uppercase " onClick={() => navigate("#leistungen")}>Leistungen</Button>
                        <Button className="btn-round mx-2 text-uppercase" onClick={() => navigate("#kontakt")}>Kontakt</Button>
                    </Nav>
                </Container>
                </Navbar>
            </nav>
        </header>
        )
    }
}

const hero = (visible, setVisible, data) => {
    return(
        <header>
            <nav className="d-none d-md-flex">
                <Navbar bg="light" fixed="top" className={visible ? "navbar-transparent" : ""}>
                <Container>
                    <Navbar.Brand href="/" className={"col-4 " + (visible ? "col-md-3" : "col-md-2")}><img src={visible ? logo_light : logo_dark} className="d-inline-block align-top img-fluid" alt="Zorrilla Media"/></Navbar.Brand>
                    <Nav className="ml-auto">
                        <Button className={"btn-link mx-2 text-uppercase " + (visible ? "text-light" : "")} onClick={() => navigate("#leistungen")}>Leistungen</Button>
                        <Button className="btn-round mx-2 text-uppercase" onClick={() => navigate("#kontakt")}>Kontakt</Button>
                    </Nav>
                </Container>
                </Navbar>
            </nav>

            <Sensor partialVisibility onChange={(isVisible) => setVisible(isVisible)}>
            <div className="page-header clear-filter" filter-color="orange">
                <div className="page-header-image">
                    <Img fluid={data.file.childImageSharp.fluid} objectFit="cover" style={{height: "100%"}}/>
                </div>
                <Container className="">
                <nav className="d-flex d-md-none mobile-nav">
                    <Navbar bg="light" className="navbar-transparent col-12">
                    <Container className="flex-column">
                        <Navbar.Brand href="/" className="col-6"><img src={logo_light} className="d-inline-block align-top img-fluid" alt="Zorrilla Media"/></Navbar.Brand>
                        <Nav className="flex-column">
                            <Button className={"btn-link mx-2 text-uppercase " + (visible ? "text-light" : "")} onClick={() => navigate("#leistungen")}>Leistungen</Button>
                            <Button className="btn-round mx-2 text-uppercase enforced-button-height" onClick={() => navigate("#kontakt")}>Kontakt</Button>
                        </Nav>
                    </Container>
                    </Navbar>
                </nav>
                <Spacer variant="small"/>
                <div className="brand text-uppercase">
                    <h1 className="font-weight-light d-none d-md-block">Wir sind <span className="text-dark">Zorrilla Media</span></h1>
                    <h2 className="font-weight-light">Ihre Medienagentur mit dem gewissen <mark>Etwas</mark></h2>
                    <h4>VON DESIGN ÜBER DRUCK BIS HIN ZU HOSTING-MANAGEMENT<br/> ALLES AUS EINER HAND UND IN HÖCHSTER QUALITÄT</h4>
                </div>
                <Arrow />
                </Container>
                <Mac />
            </div>       
            </Sensor>
        </header>)
}
