import React, {useEffect} from 'react'
import 'babel-polyfill'
import CookieConsent from "react-cookie-consent"
import 'notyf/notyf.min.css';
import {htmlUtils} from '../../functions/'
import smoothscroll from 'smoothscroll-polyfill';

import "./bootstrap.min.css"
import "./now-ui-kit.css"
import './fonts.css'
import './style.css'


export default (props) => {

  useEffect(() => {
    smoothscroll.polyfill();
  }, [])

  return(
    <section id="page" className={htmlUtils.webp() ? "webp" : ""}>
          {props.children}
    </section>
  )

}
