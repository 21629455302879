import React from "react"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { navigate} from "gatsby"

import './arrow.css'

export default () => {
    return(
        <div class="arrow bounce" onClick={() => navigate("#leistungen")}>
            <KeyboardArrowDownIcon style={{maxWidth: "25px"}}/>
        </div>
    )
}
