import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import './mac.css'

export default () => {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "mac.png" }) {
        childImageSharp { 
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return(
    <div id="mac" className="col-md-6 col-7 ml-md-5 d-none d-lg-block">
       <Img fluid={data.file.childImageSharp.fluid} style={{overflow: "visible"}}/>
    </div>
  )
}
