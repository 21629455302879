
import sanitizehtml from 'sanitize-html'
import cheerio from 'cheerio'
import * as environment from '../environment'

const sanitizerOptions = {
    allowedTags: [ 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
    'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
    'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'img' ],
    allowedAttributes: {
    a: [ 'href', 'name', 'target' ],
    img: [ 'src', 'srcset', 'alt']
    },
    selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],
    allowedSchemes: [ 'http', 'https', 'ftp', 'mailto' ],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: [ 'href', 'src', 'cite' ],
    allowProtocolRelative: true
}

export const sanatize = (string) => sanitizehtml(string, sanitizerOptions)


export const parse = (htmlstring) => cheerio.load(htmlstring)
export const setRightAlign = (dom, selector) => {
    let elements = dom(selector)
    elements.map((i, elem) => {
        dom(elem).attr('class', "alignright")
    })
}
export const applyImageThumbnails = (dom, selector, maxwidth, maxheight) => {
    let elements = dom(selector)
    elements.map((i, elem) => {
        let src = dom(elem).attr('src').replace(environment.API_IMAGES_ORIGINAL, environment.API_IMAGES_THUMBNAIL(maxwidth, maxheight, "better"))
        dom(elem).attr('src', src)
    })
}

export const replaceThumbnail = (url, maxwidth, maxheight) => {
    return url.replace(environment.API_IMAGES_ORIGINAL, environment.API_IMAGES_THUMBNAIL(maxwidth, maxheight, "better"))
}

export const webp = () => typeof document !== `undefined` ? document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') == 0 : false;