import React from "react"

export default () => {

    return(
            <footer class="site-footer col-12 mt-5">
                <div class="container">
                    <div class="row justify-content-between">
                    <div class="col-12 col-md-5 mb-5 pb-4">
                        <h6>Über uns</h6>
                        <p class="text-justify">Zorrilla Media ist Ihre kompetente Medienagentur für alle Themen Rund um Design, Web und Druck. Wir helfen Ihrem Unternehmen und Ihrer Marke zum Erfolg. Mit mehr als 10 Jahren Berufserfahrung können wir Sie kundenindividuell und kompetent unterstützen.</p>
                    </div>
                    <div className="col-12 col-md-5 flex-row d-flex justify-content-between">
                        <div class="col-6">
                            <h6>Quick Links</h6>
                            <ul class="list-unstyled">
                            <li><a href="https://webmail.zorrillamedia.de">Zorrilla Webmail</a></li>
                            <li><a href="http://mailconfig.zorrillamedia.de">Zorrilla Mailkonfiguration</a></li>
                            <li><a href="https://analytics.zorrillamedia.de">Zorrilla Analytics</a></li>
                            </ul>
                        </div>
                        <div class="col-4">
                            <h6>Support</h6>
                            <ul class="list-unstyled">
                                <li><a href="mailto:%68%65llo@%7a%6fr%72%69%6c%6ca%6dedi%61.%64%65">Mail-Support</a></li>
                                <li><a href="#contactform">Konktaktformular</a></li>
                                <li><a href="tel:00496144920499910">Telefon-Support</a></li>
                            </ul>
                        </div>
                    </div>
                    </div>
                    <hr/>
                </div>
                <div class="container">
                    <div class="row">
                    <div class="col-md-8 col-sm-6 col-xs-12">
                        <small>Copyright &copy; {new Date().getFullYear()} All Rights Reserved by <a href="#">Zorrilla Media</a></small>
                    </div>

                    <div class="col-md-4 col-sm-6 col-xs-12">
                            <ul class="list-unstyled list-inline text-right">
                                <li className="list-inline-item"><a href="/impressum">Impressum</a></li>
                                <li className="list-inline-item"><a href="/datenschutz">Datenschutz</a></li>
                                <li className="list-inline-item"><a href="/agb">AGB</a></li>
                            </ul>
                    </div>
                    </div>
                </div>
            </footer>
    )
}
